import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'calsoft-editor-text',
  templateUrl: './editor-text.component.html',
  styleUrls: ['./editor-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorTextComponent implements OnInit {
  trustedHtmlContent: any;

  @Input() text: any;

  @Input() align: any;

  @Input() data: any;

  @Input() fontSize: any;

  @Input() MobfontSize: any;

  @Input() fontFamily: any;

  @Input() id: any;

  @Input() matIcon: any = 'check_circle';

  arry: any=[];
  
  icon: boolean;

  constructor(
   private sanitizer: DomSanitizer,
   

  ) {
    
  }

  ngOnInit() {

  //   if(this.text.includes('ul')){
  //     this.icon=true;
  //   }else{
  //     this.icon=false;
  //   }
  //   const containsUlAndLiTags = this.checkUlAndLiTags(this.text);
  //   if (!containsUlAndLiTags) {
  //   //  console.log(this.text);
  //   this.text = this.text.replace(/<ul>/g, '')
  //   .replace(/<\/?li>/g, '')
  //   .replace(/<\/?p>/g, '')
  //   .replace(/<br>/g, '');

  //   this.arry = this.text.split('</ul>');
  //   this.arry = this.arry.filter(item => item.trim() !== '');  

  //   if(this.arry.length==1){
  //     this.icon=false;
  //   }
  // }else{
  //   this.text = this.text.replace(/<ul>/g, '')
  //   .replace(/<br>/g, '')
  //   .replace(/<li>/g, '')
  //   .replace(/<p>/g, '')
  //   ;

  //   this.arry = this.text.split('</p></li>');

  //   this.arry.splice(this.arry.length-1, 1);


  //   //console.log(this.arry);
    

  // }
  }

  // checkUlAndLiTags(input: string): boolean {
  //   const ulLiPattern = /<ul>\s*(<li>.*<\/li>\s*)+<\/ul>/;
  //   return ulLiPattern.test(input);
  // }

}
